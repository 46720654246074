<template>
  <v-row class="app-receipt-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="app-receipt">
        <!-- Header -->
        <div class="py-5 px-5">
          <Letterhead :width="'100%'" />

          <div class="d-flex flex-wrap justify-space-between mt-9">
            <!-- Date -->
            <div class="d-flex align-center">
              <span class="me-2 font-weight-medium text--primary">Date Issued: {{ receipt.issued_at }}</span>
            </div>

            <!-- Receipt number -->
            <div
              class="d-flex align-center justify-end"
              :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
            >
              <div>
                <v-select
                  v-model="receipt.branch_id"
                  :items="branchOptions"
                  item-value="id"
                  item-text="name"
                  single-line
                  outlined
                  dense
                  hide-details
                  clearable
                  placeholder="Select Branch"
                  class="receipt-add-branch"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-select>
                <RequestHint
                  v-if="request && request.branch && !isApprovedRequest"
                  :is-attr-change="receipt.branch_id !== request.branch.id"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.branch.name"
                />
              </div>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <v-expansion-panels class="pa-5">
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              1. Customer Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-5">
              <!-- Customer Details -->
              <div class="mb-3 d-flex">
                <div class="px-3 flex-grow-1 font-weight-semibold">
                  <v-row>
                    <!-- Customer Type -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Customer Type:
                      </p>
                      <v-select
                        v-model="receipt.customer_type"
                        :items="customerTypeOptions"
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Select Customer Type"
                        @input="customerTypeChange(), netPaymentRef.validate()"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-select>
                      <RequestHint
                        v-if="request && request.customer_type && !isApprovedRequest"
                        :is-attr-change="receipt.customer_type !== request.customer_type"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.customer_type"
                      />
                    </v-col>

                    <!-- Select Broker -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                      v-if="receipt.customer_type === 'Non quoted/Broker'"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Select Broker:
                      </p>
                      <v-select
                        v-model="receipt.broker_id"
                        :items="brokerOptions"
                        item-value="id"
                        item-text="name"
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Select Broker"
                        @input="brokerChange()"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-select>
                      <RequestHint
                        v-if="request && request.broker && !isApprovedRequest"
                        :is-attr-change="receipt.broker_id !== request.broker.id"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.broker.name"
                      />
                    </v-col>

                    <!-- Select Corporate -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                      v-if="receipt.customer_type === 'Corporate'"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Select Corporate:
                      </p>
                      <v-select
                        v-model="receipt.corporate_id"
                        :items="corporateOptions"
                        item-value="id"
                        item-text="name"
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Select Corporate"
                        @input="corporateChange()"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-select>
                      <RequestHint
                        v-if="request && request.corporate && !isApprovedRequest"
                        :is-attr-change="receipt.corporate_id !== request.corporate.id"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.corporate.name"
                      />
                    </v-col>

                    <!-- Select Niagamas Loyalty -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                      v-if="receipt.customer_type === 'Niagamas loyalty'"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Select Loyalty:
                      </p>
                      <v-select
                        v-model="receipt.niagamas_loyalty_id"
                        :items="loyaltyOptions"
                        item-value="id"
                        item-text="name"
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Select Loyalty Customer"
                        @input="loyaltyChange()"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-select>
                      <RequestHint
                        v-if="request && request.niagamas_loyalty && !isApprovedRequest"
                        :is-attr-change="receipt.niagamas_loyalty_id !== request.niagamas_loyalty.id"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.niagamas_loyalty.name"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- Customer Name -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Receipt From:
                      </p>
                      <v-text-field
                        v-model="receipt.customer_name"
                        single-line
                        dense
                        outlined
                        hide-details
                        :placeholder="(receipt.customer_type === 'Non quoted/Broker' || receipt.customer_type === 'Corporate' || receipt.customer_type === 'Niagamas loyalty') ? 'Policy Holder Name' : 'Customer Name'"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-text-field>
                      <RequestHint
                        v-if="request && !isApprovedRequest"
                        :is-attr-change="receipt.customer_name !== request.customer_name"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.customer_name"
                      />
                    </v-col>

                    <!-- Customer Contact -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                      v-if="receipt.customer_type !== 'Non quoted/Broker' && receipt.customer_type !== 'Corporate' && receipt.customer_type !== 'Niagamas loyalty'"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Contact No.
                      </p>
                      <v-text-field
                        v-model="receipt.customer_contact_number"
                        single-line
                        dense
                        outlined
                        type="number"
                        :rules="[v => /[0-9\-().\s]{7}$/.test(v) || 'Must be valid contact number']"
                        hide-details="auto"
                        :placeholder="'Customer Contact Number'"
                        prefix="+673"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-text-field>
                      <RequestHint
                        v-if="request && !isApprovedRequest"
                        :is-attr-change="receipt.customer_contact_number !== request.customer_contact_number"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="`+673 ${request.customer_contact_number}`"
                      />
                    </v-col>

                    <!-- Customer IC/ID Number -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                      v-if="receipt.customer_type === 'Staff rate'"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        IC/ID Number:
                      </p>
                      <v-text-field
                        v-model="receipt.customer_ic_number"
                        single-line
                        dense
                        outlined
                        hide-details
                        :placeholder="'Customer IC/ID Number'"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-text-field>
                      <RequestHint
                        v-if="request && !isApprovedRequest"
                        :is-attr-change="receipt.customer_ic_number !== request.customer_ic_number"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.customer_ic_number"
                      />
                    </v-col>
                  </v-row>
                </div>
                <div class="header-spacer"></div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              2. Insurance Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-5">
              <!-- Receipt Details -->
              <div class="mb-3 d-none d-md-flex">
                <div class="px-3 flex-grow-1 font-weight-semibold">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <span>Description</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Amount</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Summable Amount</span>
                    </v-col>
                  </v-row>
                </div>
                <div class="header-spacer"></div>
              </div>

              <!-- Insurance Category -->
              <v-card
                outlined
                class="d-flex pa-3"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.insurance_category_id"
                      :items="insuranceCategoryOptions"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Select Insurance Category"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      @input="netPaymentRef.validate()"
                    ></v-select>
                    <RequestHint
                      v-if="request && request.insurance_category && !isApprovedRequest"
                      :is-attr-change="receipt.insurance_category_id !== request.insurance_category.id"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.insurance_category.name"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- Insurance Product Type -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.insurance_product_id"
                      :items="insuranceProductOptions"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Select Insurance Product Type"
                      :disabled="typeof receipt.invoice_id === 'number' || auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      @change="clearRoadTaxInfo()"
                    ></v-select>
                    <RequestHint
                      v-if="request && request.insurance_product && !isApprovedRequest"
                      :is-attr-change="receipt.insurance_product_id !== request.insurance_product.id"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.insurance_product.name"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- Insurance Policy Number -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="receipt.insurance_policy_number"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Insurance Policy Number"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-text-field>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.insurance_policy_number !== request.insurance_policy_number"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.insurance_policy_number"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- Car Registration Number -->
              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="receipt.car_registration_number"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Car Registration Number"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-text-field>
                    <RequestHint
                      v-if="request && request && !isApprovedRequest"
                      :is-attr-change="receipt.car_registration_number !== request.car_registration_number"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.car_registration_number"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- Insurance -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.insurance_id"
                      :items="insuranceOptions"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Select Principle"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-select>
                    <RequestHint
                      v-if="request && request.insurance && !isApprovedRequest"
                      :is-attr-change="receipt.insurance_id !== request.insurance.id"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.insurance.name"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- Sum Insured -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    class="d-flex align-center"
                  >
                    <p class="mb-0">
                      Sum Insured
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="receipt.sum_insured"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-text-field>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.sum_insured !== request.sum_insured"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="`$${formatCurrency(request.sum_insured)}`"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- Insurance Premium -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    class="d-flex align-center"
                  >
                    <p class="mb-0">
                      Insurance Premium
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="receipt.insurance_premium"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-text-field>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.insurance_premium !== request.insurance_premium"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="`$${formatCurrency(request.insurance_premium)}`"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- After Discount -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    class="d-flex align-center"
                  >
                    <p class="mb-0">
                      After Discount
                    </p>
                    <p
                      v-if="receipt.payment_method_id == 5"
                      class="text-xs mb-0"
                    >
                      Kindly add 2.4% additional charges for card payments.
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    offset-sm="3"
                    sm="3"
                  >
                    <v-text-field
                      v-model="receipt.after_discount"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      @input="netPaymentRef.validate()"
                    ></v-text-field>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.after_discount !== request.after_discount"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="`$${formatCurrency(request.after_discount)}`"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- Net Payment -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                  >
                    <p class="mb-0">
                      Net Payment
                      <span class="text-xs">(optional)</span>
                    </p>
                    <p class="text-xs mb-0">
                      For internal use only. This will not be printed or added to current total.
                      <br>
                      <span v-if="receipt.payment_method_id == 5">Kindly add 2.4% additional charges for card payments.</span>
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    offset-sm="1"
                    sm="3"
                  >
                    <v-text-field
                      ref="netPaymentRef"
                      v-model="receipt.net_payment"
                      :rules="netPaymentRules"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-text-field>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.net_payment !== request.net_payment"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.net_payment ? `$${formatCurrency(request.net_payment)}` : null"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- Insurance Policy -->
              <v-card
                v-if="((receipt.insurance_policy_url || !(auditorDisabled || voidRequestDisabled || isApprovedRequest)) && (receipt.insurance_category_id != 4)) || request.request_insurance_policy_url"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    v-if="receipt.insurance_policy_url || request.request_insurance_policy_url"
                    cols="12"
                    sm="6"
                  >
                    <p class="mb-0">
                      Insurance Policy
                    </p>

                    <div v-if="!receipt.insurance_policy_url">
                      <small
                        v-if="request.request_insurance_policy_url !== 'Not Found'"
                        class="primary--text"
                      >
                        Empty -->
                      </small>
                      <a
                        v-if="request.request_insurance_policy_url !== 'Not Found'"
                        :href="request.request_insurance_policy_url"
                        target="_blank"
                        class="font-weight-semibold"
                      >
                        <small>{{ request.request_insurance_policy_name }}</small>
                        <v-icon
                          size="18"
                          color="primary"
                        >{{ icons.mdiOpenInNew }}</v-icon>
                      </a>
                      <div v-else>
                        <small class="error--text">
                          Requested attachment seems to be missing or corrupted, please ask counter to reattach again
                        </small>
                      </div>
                    </div>

                    <div v-else>
                      <a
                        :href="receipt.insurance_policy_url"
                        target="_blank"
                      >
                        <small>{{ receipt.insurance_policy_name }}</small>
                        <v-icon
                          size="18"
                          color="primary"
                        >{{ icons.mdiOpenInNew }}</v-icon>
                      </a>
                      <div v-if="request.request_insurance_policy_url !== 'Not Found'">
                        <RequestHint
                          v-if="request && request.request_insurance_policy_url && !isApprovedRequest"
                          :is-attr-change="receipt.insurance_policy !== request.insurance_policy"
                          :is-auditor-respond="auditorDisabled"
                          :is-request-void="isRequestVoid"
                          :hint="request.request_insurance_policy_name"
                          :link="request.request_insurance_policy_url"
                        />
                      </div>
                      <div v-else>
                        <small class="error--text">
                          Requested attachment seems to be missing or corrupted, please ask counter to reattach again
                        </small>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    v-if="!(auditorDisabled || voidRequestDisabled || isApprovedRequest)"
                    cols="12"
                    sm="6"
                  >
                    <v-file-input
                      v-model="newInsurancePolicy"
                      prepend-icon=""
                      :append-icon="icons.mdiPaperclip"
                      accept="application/pdf, image/png, image/jpg, image/jpeg"
                      show-size
                      dense
                      outlined
                      :rules="policyUploadRules"
                      :label="receipt.insurance_policy_url ? 'Upload new insurance policy' : 'Upload insurance policy'"
                      hide-details="auto"
                      @change="uploadCountChange"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          small
                          label
                          color="primary"
                        >
                          {{ text }}
                        </v-chip>
                      </template>

                      <template v-slot:append>
                        <v-progress-circular
                          v-if="uploadLoading"
                          color="primary"
                          indeterminate
                          size="25"
                        ></v-progress-circular>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-card>

              <v-card
                v-if="(receipt.insurance_category_id === 1 || (request.insurance_category && request.insurance_category.id === 1)) &&
                (receipt.insurance_product_id === 1 || (request.insurance_category && request.insurance_product.id === 1))"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-card-text class="pa-0">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <p>
                        Insurance expiry date:
                      </p>
                      <!-- Expiry Date-->
                      <v-menu
                        v-model="isExpiryDateMenuOpen"
                        :close-on-content-click="!isExpiryDateMenuOpen"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="expiryDateFormatted"
                            :append-icon="icons.mdiCalendar"
                            placeholder="Not set"
                            readonly
                            dense
                            outlined
                            hide-details
                            clearable
                            class="mt-2"
                            :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                            v-bind="attrs"
                            v-on="on"
                            @click="isExpiryDateMenuOpen = true"
                            @click:clear="receipt.insurance_expired_at = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="receipt.insurance_expired_at"
                          color="primary"
                          type="month"
                          no-title
                          @click:month="isExpiryDateMenuOpen = false"
                        ></v-date-picker>
                      </v-menu>
                      <RequestHint
                        v-if="request && !isApprovedRequest"
                        :is-attr-change="receipt.insurance_expired_at !== request.insurance_expired_at"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.insurance_expired_at"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="isMotorInsurance">
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              3. Roadtax Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-5">
              <!-- Road Tax Price -->
              <div class="mb-3 d-none d-md-flex">
                <div class="px-3 flex-grow-1 font-weight-semibold">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <span>Description</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Amount</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Summable Amount</span>
                    </v-col>
                  </v-row>
                </div>
                <div class="header-spacer"></div>
              </div>

              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    class="d-flex align-center"
                  >
                    <p class="mb-0">
                      Road Tax Price
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    offset-sm="3"
                    sm="3"
                  >
                    <v-text-field
                      v-model="receipt.road_tax"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-text-field>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.road_tax !== request.road_tax"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.road_tax ? `$${formatCurrency(request.road_tax)}` : null"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- Require inspection -->
              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.require_inspection"
                      :items="[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Does vehicle need inspection?"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      @change="receipt.crw_number = null"
                    ></v-select>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.require_inspection !== request.require_inspection"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.require_inspection ? 'Yes' : 'No'"
                    />

                    <template>
                      <v-text-field
                        v-if="receipt.require_inspection"
                        v-model="receipt.crw_number"
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Enter CRW no."
                        class="mt-3"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-text-field>
                      <RequestHint
                        v-if="request && receipt.crw_number && !isApprovedRequest"
                        :is-attr-change="receipt.crw_number !== request.crw_number"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.crw_number"
                      />
                    </template>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Require additional time to process -->
              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.require_additional_process_time"
                      :items="[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Does roadtax require additional time to process?"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      @change="updateProcessingDate($event)"
                    ></v-select>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.require_additional_process_time !== request.require_additional_process_time"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.require_additional_process_time ? request.processing_date : 'No'"
                    />
                    <v-menu
                      v-if="receipt.require_additional_process_time"
                      v-model="isAdditionalDateMenuOpen"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :append-icon="icons.mdiCalendar"
                          :value="new Date(receipt.processing_date).toLocaleDateString()"
                          placeholder="'Enter processing date'"
                          readonly
                          dense
                          outlined
                          hide-details
                          class="mt-3"
                          :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="receipt.processing_date"
                        :min="new Date().toISOString().substring(0, 10)"
                        color="primary"
                        :first-day-of-week="1"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                        @input="isAdditionalDateMenuOpen = false"
                      >
                      </v-date-picker>
                    </v-menu>
                    <RequestHint
                      v-if="request && receipt.processing_date && !isApprovedRequest"
                      :is-attr-change="new Date(receipt.processing_date).toLocaleDateString() !== request.processing_date"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.processing_date"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- Request for Express Service -->
              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.express_charges"
                      :items="[{ id: '10.00', name: 'Yes' }, { id: '0.00', name: 'No' }]"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Request for Express Service?"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-select>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.express_charges !== request.express_charges"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.express_charges == '10.00' ? 'Yes' : 'No'"
                    />
                  </v-col>
                  <v-col
                    v-if="receipt.express_charges === '10.00'"
                    cols="12"
                    offset-sm="3"
                    sm="3"
                    class="d-flex align-center"
                  >
                    <span>$10.00</span>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Request for Delivery Service -->
              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.delivery_charges"
                      :items="[{ id: '10.00', name: 'Yes' }, { id: '0.00', name: 'No' }]"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Request for Delivery Service?"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      @change="clearDeliveryAddress()"
                    ></v-select>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.delivery_charges !== request.delivery_charges"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.delivery_charges == '10.00' ? 'Yes' : 'No'"
                    />
                    <template>
                      <v-textarea
                        v-if="receipt.delivery_charges === '10.00'"
                        v-model="receipt.remarks.address"
                        placeholder="Delivery Address"
                        dense
                        outlined
                        rows="3"
                        hide-details
                        class="mt-3"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-textarea>
                      <RequestHint
                        v-if="request && request.address_remarks && !isApprovedRequest"
                        :is-attr-change="receipt.address_remarks !== request.address_remarks"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.address_remarks"
                      />
                    </template>
                  </v-col>
                  <v-col
                    v-if="receipt.delivery_charges === '10.00'"
                    cols="12"
                    offset-sm="3"
                    sm="3"
                    class="d-flex align-center"
                  >
                    <span>$10.00</span>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Pickup Location -->
              <v-card
                v-if="isMotorInsurance || request.pickup_location"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.pickup_location_id"
                      :items="pickupLocationOptions"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      :placeholder="receipt.pickup_location_id ? 'Select Road Tax Pickup Location' : 'Road Tax Pickup Location'"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-select>
                    <RequestHint
                      v-if="request && request.pickup_location && !isApprovedRequest"
                      :is-attr-change="receipt.pickup_location_id !== request.pickup_location.id"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.pickup_location_remarks || request.pickup_location.name"
                    />

                    <template>
                      <v-text-field
                        v-if="isOtherPickupLocation"
                        v-model="receipt.remarks.pickup_location"
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Enter Road Tax Pickup Location"
                        class="mt-3"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-text-field>
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              {{ isMotorInsurance ? '4' : '3' }}. Additional Charges Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class=pa-5>
              <!-- Additional Fees -->
              <div class="mb-3 d-none d-md-flex">
                <div class="px-3 flex-grow-1 font-weight-semibold">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <span>Description</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Amount</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Summable Amount</span>
                    </v-col>
                  </v-row>
                </div>
                <div class="header-spacer"></div>
              </div>

              <v-card
                outlined
                class="d-flex pa-3"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    class="d-flex align-center"
                  >
                    <p class="mb-0">
                      Additional Fees
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    offset-sm="3"
                    sm="3"
                  >
                    <v-text-field
                      v-model="receipt.additional_fees"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-text-field>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.additional_fees !== request.additional_fees"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.additional_fees ? `$${formatCurrency(request.additional_fees)}` : null"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="pt-0"
                  >
                    <v-select
                      v-model="receipt.additional_fees_info_id"
                      :items="additionalFeesInfoOptions"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      :placeholder="receipt.additional_fees_info_id ? 'Select Additional Fees Info' : 'Additional Fees Info'"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-select>
                    <RequestHint
                      v-if="request && request.additional_fees_info && !isApprovedRequest"
                      :is-attr-change="receipt.additional_fees_info_id !== request.additional_fees_info.id"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.additional_fees_remarks || request.additional_fees_info.name"
                    />

                    <template>
                      <v-text-field
                        v-if="isOtherAdditionalFeesInfo"
                        v-model="receipt.remarks.additional_fees"
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Additional Fees Remarks"
                        class="mt-3"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-text-field>
                      <RequestHint
                        v-if="isOtherAdditionalFeesInfo && request && request.additional_fees_remarks && !isApprovedRequest"
                        :is-attr-change="receipt.additional_fees_remarks !== request.additional_fees_remarks"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.additional_fees_remarks"
                      />
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              {{ isMotorInsurance ? '5' : '4' }}. Discount Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-5">
              <v-card
                v-if="!(auditorDisabled || voidRequestDisabled || isApprovedRequest) || (voucherIssueAmount || request.voucher_issued_amount)"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-card-text class="pa-0">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <div v-if="voucherIssued">
                        <div class="font-weight-semibold mb-3">Edit Voucher Issued</div>
                        <v-row class="my-0">
                          <v-col class="py-0">
                            <div>Code:</div>
                          </v-col>
                          <v-col class="text-right py-0 font-weight-semibold">
                            <div>{{ voucherIssued.code }}</div>
                          </v-col>
                        </v-row>
                        <v-row class="my-1">
                          <v-col class="py-0 mt-3">
                            <div>Amount:</div>
                          </v-col>
                          <v-col
                            cols="5"
                            class="py-0"
                          >
                            <v-select
                              v-model="voucherIssueAmount"
                              :items="editVoucherAmountOptions"
                              item-text="name"
                              item-value="id"
                              dense
                              outlined
                              hide-details="auto"
                              class="voucher-amount-edit mt-4"
                              :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                            ></v-select>
                            <RequestHint
                              v-if="request && !isApprovedRequest"
                              :is-attr-change="receipt.voucher_issued_amount !== request.voucher_issued_amount"
                              :is-auditor-respond="auditorDisabled"
                              :is-request-void="isRequestVoid"
                              :hint="request.voucher_issued_amount ? `$${formatCurrency(request.voucher_issued_amount)}` : null"
                            />
                          </v-col>
                        </v-row>
                        <div v-if="voucherIssued.receipt_url">
                          <div>Used at:</div>
                          <a
                            class="font-weight-medium text-decoration-none"
                            :href="voucherIssued.receipt_url"
                          >
                            <small>{{ voucherIssued.receipt_url }}</small>
                          </a>
                        </div>
                      </div>
                      <div v-else>
                        <v-select
                          v-model="voucherIssueAmount"
                          :items="voucherAmountOptions"
                          item-text="name"
                          item-value="id"
                          :hint="!(auditorDisabled || voidRequestDisabled || isApprovedRequest) ? t('Select amount to issue a voucher to customer for future use') : ''"
                          persistent-hint
                          dense
                          outlined
                          hide-details="auto"
                          :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                        ></v-select>
                        <RequestHint
                          v-if="request && !isApprovedRequest"
                          :is-attr-change="Boolean(request.voucher_issued_amount)"
                          :is-auditor-respond="auditorDisabled"
                          :is-request-void="isRequestVoid"
                          :hint="request.voucher_issued_amount ? `$${formatCurrency(request.voucher_issued_amount)}` : null"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Voucher -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-if="!auditorDisabled"
                      v-model="voucher.code"
                      outlined
                      dense
                      maxlength="4"
                      hint="This is the 4-digit code on the voucher coupon, e.g. 0051, 1147, 2089, etc."
                      persistent-hint
                      hide-details="auto"
                      placeholder="Voucher Code (optional)"
                      :disabled="isApprovedRequest"
                      @input="voucher.code.length === 4 ? checkVoucher() : clearVoucherUse(), netPaymentRef.validate()"
                      @keypress="isNumber"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      v-model="receipt.voucher_code"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="NULL"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                    ></v-text-field>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.voucher_code !== request.voucher_code"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.voucher_code ? `${request.voucher_code} (Voucher code)` : 'NULL'"
                    />
                  </v-col>
                  <v-col
                    v-if="voucher.amount || (auditorDisabled && receipt.voucher_amount)"
                    cols="12"
                    offset-sm="3"
                    sm="3"
                  >
                    <div class="mt-0 mt-sm-2">
                      $ {{ voucher.amount }}
                    </div>
                    <RequestHint
                      v-if="request && !isApprovedRequest"
                      :is-attr-change="receipt.voucher_amount !== request.voucher_amount"
                      :is-auditor-respond="auditorDisabled"
                      :is-request-void="isRequestVoid"
                      :hint="request.voucher_amount ? request.voucher_amount : '$0.00'"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              {{ isMotorInsurance ? '6' : '5' }}. Payment Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-5">
              <div class="mb-3 d-flex">
                <div class="px-3 flex-grow-1 font-weight-semibold">
                  <v-row>
                    <!-- Payment Method -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Payment By:
                      </p>
                      <v-select
                        v-model="receipt.payment_method_id"
                        :items="paymentMethodOptions"
                        item-value="id"
                        item-text="name"
                        single-line
                        outlined
                        dense
                        hide-details
                        clearable
                        placeholder="Select Payment Method"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                        @change="clearPaymentDetailNumbers()"
                      ></v-select>
                      <RequestHint
                        v-if="request && request.payment_method && !isApprovedRequest"
                        :is-attr-change="receipt.payment_method_id !== request.payment_method.id"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.payment_method.name"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Bank Payment Screenshot -->
                    <v-col
                      v-if="receipt.payment_method_id == 1 || receipt.payment_method_id == 2 || request.request_payment_screenshot_url"
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Bank Payment Screenshot:
                      </p>
                      <div
                        v-if="receipt.payment_screenshot_url || request.request_payment_screenshot_url"
                        class="mb-3"
                      >
                        <div v-if="!receipt.payment_screenshot_url">
                          <small
                            v-if="request.request_payment_screenshot_url !== 'Not Found'"
                            class="primary--text font-weight-medium pl-2"
                          >
                            Empty -->
                          </small>
                          <a
                            v-if="request.request_payment_screenshot_url !== 'Not Found'"
                            :href="request.request_payment_screenshot_url"
                            target="_blank"
                            class="font-weight-semibold"
                          >
                            <small>{{ request.request_payment_screenshot_name }}</small>
                            <v-icon
                              size="18"
                              color="primary"
                            >{{ icons.mdiOpenInNew }}</v-icon>
                          </a>
                          <div v-else>
                            <small class="error--text font-weight-medium">
                              Requested attachment seems to be missing or corrupted, please ask counter to reattach again
                            </small>
                          </div>
                        </div>

                        <div v-else>
                          <a
                            :href="receipt.payment_screenshot_url"
                            target="_blank"
                            class="font-weight-medium"
                          >
                            <small>{{ receipt.payment_screenshot_name }}</small>
                            <v-icon
                              size="18"
                              color="primary"
                            >{{ icons.mdiOpenInNew }}</v-icon>
                          </a>
                          <div v-if="request.request_payment_screenshot_url !== 'Not Found'">
                            <RequestHint
                              v-if="receipt.payment_screenshot_url && request && request.request_payment_screenshot_url && !isApprovedRequest"
                              :is-attr-change="receipt.payment_screenshot !== request.payment_screenshot"
                              :is-auditor-respond="auditorDisabled"
                              :is-request-void="isRequestVoid"
                              :hint="request.request_payment_screenshot_name"
                              :link="request.request_payment_screenshot_url"
                            />
                          </div>
                          <div v-else>
                            <small class="error--text font-weight-medium">
                              Requested attachment seems to be missing or corrupted, please ask counter to reattach again
                            </small>
                          </div>
                        </div>
                      </div>
                      <v-file-input
                        v-if="!(auditorDisabled || voidRequestDisabled || isApprovedRequest)"
                        v-model="newPaymentScreenshot"
                        prepend-icon=""
                        :append-icon="icons.mdiPaperclip"
                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                        show-size
                        dense
                        outlined
                        :rules="paymentScreenshotRules"
                        :placeholder="receipt.payment_screenshot_url ? 'Upload new payment screenshot' : 'Upload payment screenshot'"
                        hide-details="auto"
                        @change="uploadCountChange"
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip
                            small
                            label
                            color="primary"
                          >
                            {{ text }}
                          </v-chip>
                        </template>

                        <template v-slot:append>
                          <v-progress-circular
                            v-if="uploadScreenshotLoading"
                            color="primary"
                            indeterminate
                            size="25"
                          ></v-progress-circular>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Trace Number -->
                    <v-col
                      v-if="receipt.payment_method_id == 5 || (request && (receipt.trace_number !== request.trace_number) && auditorDisabled && !isRequestVoid)"
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Trace Number:
                      </p>
                      <v-text-field
                        v-model="receipt.trace_number"
                        single-line
                        dense
                        outlined
                        hide-details
                        :placeholder="!auditorDisabled ? 'Enter 5 digit Trace No.' : 'NULL'"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-text-field>
                      <RequestHint
                        v-if="request && !isApprovedRequest"
                        :is-attr-change="receipt.trace_number !== request.trace_number"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.trace_number"
                      />
                    </v-col>

                    <!-- Card Number -->
                    <v-col
                      v-if="receipt.payment_method_id == 5 || (request && (receipt.partial_card_number !== request.partial_card_number) && auditorDisabled && !isRequestVoid)"
                      cols="12"
                      sm="6"
                      :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Card Number:
                      </p>
                      <v-text-field
                        v-model="receipt.partial_card_number"
                        single-line
                        dense
                        outlined
                        hide-details
                        :placeholder="!auditorDisabled ? 'Enter Card No. last 4 digits' : 'NULL'"
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      ></v-text-field>
                      <RequestHint
                        v-if="request && !isApprovedRequest"
                        :is-attr-change="receipt.partial_card_number !== request.partial_card_number"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.partial_card_number"
                      />
                    </v-col>
                  </v-row>
                </div>
                <div class="header-spacer"></div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-divider></v-divider>

        <div class="py-5 px-5">
          <!-- General remarks -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-textarea
                  v-model="receipt.remarks.general"
                  dense
                  outlined
                  rows="3"
                  hide-details="auto"
                  placeholder="General Remarks"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-textarea>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="receipt.remarks.general !== request.general_remarks"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.general_remarks"
                />
              </v-col>
            </v-row>
          </v-card>
        </div>

        <v-divider></v-divider>

        <!-- Receipt Footer -->
        <div class="py-5 px-5">
          <!-- Issuer -->
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="d-flex align-center"
            >
              <p class="mb-0 pr-2 font-weight-semibold">
                Issued By:
              </p>
              <p class="mb-0">
                {{ receipt.issuer }}
              </p>
            </v-col>

            <v-col
              cols="4"
              sm="3"
              class="d-flex align-center justify-end"
            >
              <p class="mb-0 font-weight-semibold">
                Total:
              </p>
            </v-col>

            <!-- Total -->
            <v-col
              cols="8"
              sm="3"
              :class="{ 'px-0': $vuetify.breakpoint.xsOnly }"
            >
              <p class="mb-0 text-xl font-weight-semibold">
                ${{ calculatedTotal }}
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card
        v-if="!isApprovedRequest"
        class="mb-6"
      >
        <v-card-text>
          <!-- Auditor view request -->
          <v-btn
            v-if="can('write', 'ReportActions') && requestId"
            color="primary"
            block
            depressed
            :disabled="isRespondedRequest || receipt.deleted_at"
            class="mb-2"
            @click="isApproveDialogVisible = true"
          >
            {{ t('Approve Request') }}
          </v-btn>

          <!-- Auditor view request -->
          <v-btn
            v-if="can('write', 'ReportActions') && requestId"
            color="primary"
            outlined
            depressed
            block
            :disabled="isRespondedRequest"
            @click="isRejectDialogVisible = true"
          >
            {{ t('Reject Request') }}
          </v-btn>

          <!-- Create & edit receipt -->
          <v-btn
            v-else-if="can('write', 'ReportActions')"
            color="primary"
            depressed
            block
            :loading="loading"
            :disabled="loading"
            @click="handleUploads('auditor')"
          >
            {{ t('Confirm Receipt') }}
          </v-btn>

          <!-- Void request -->
          <v-btn
            v-else-if="router.currentRoute.params.action === 'Void' || receipt.deleted_at"
            color="error"
            depressed
            block
            :disabled="isApprovedRequest"
            @click="isVoidDialogVisible = true"
          >
            {{ t('Remove Request') }}
          </v-btn>

          <!-- Create & edit request -->
          <v-btn
            v-else
            color="primary"
            depressed
            block
            :disabled="isApprovedRequest"
            @click="isCommentDialogVisible = true"
          >
            {{ t('Send Request') }}
          </v-btn>

          <div
            v-if="receipt.deleted_at"
            class="text-center mt-4"
          >
            {{ can('write', 'ReportActions') ? t('Receipt has been voided, please inform requester to remove this request.')
              : t('Receipt has been voided, please remove this request as it cannot be saved.') }}
          </div>

          <div
            v-else-if="!auditorDisabled && router.currentRoute.params.action !== 'Void'"
            class="text-center mt-4"
          >
            {{ t('Please ensure that all fields are filled correctly as this record will not be editable after confirming.') }}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="errors.length != 0"
        class="mb-6"
      >
        <v-card-text>
          <Alert
            :messages="errors"
            color="error"
          ></Alert>
        </v-card-text>
      </v-card>
      <v-card
        v-if="requestId && !isApprovedRequest && can('write', 'ReportActions')"
        class="mb-6"
      >
        <v-card-text v-if="request.requested_by">
          <div v-if="isRequestVoid">
            {{ request.requested_by.nickname }} has request to void this receipt.
          </div>
          <div v-else>
            <div class="mb-2">
              {{ request.requested_by.nickname }} has request to edit this receipt.
            </div>
            <div>The <span class="primary--text font-weight-semibold">requested changes</span> is hinted below each receipt field, ensure these changes are appropriate as this receipt will not be reversible after approval.</div>
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="isApprovedRequest"
        class="mb-6"
      >
        <v-card-text>
          <div>
            This request has been approved & no longer editable.
          </div>
        </v-card-text>
      </v-card>
      <RequestComments
        v-if="requestId"
        :comments="comments"
        :fetch-comments="fetchComments"
      />
    </v-col>

    <!-- Void dialog -->
    <v-dialog
      v-model="isVoidDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Void request on {{ request.record_number || receipt.receipt_number }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isVoidDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          This request will be voided. It won't be taken into any calculations.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="voidLoading"
            :disabled="voidLoading"
            class="mt-3"
            @click="voidRequest()"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Comment dialog -->
    <v-dialog
      v-model="isCommentDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Request changes?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isCommentDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-textarea
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-4"
          ></v-textarea>
          <p>
            {{ requestId ? 'This updates the edit request & changes status to Pending for auditor to respond.' :
              'This action will create an edit request & the changes will be saved after approved by auditor.' }}
          </p>
          <p class="mb-0">
            Please leave any remarks that the auditor should know about the changes.
          </p>
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="handleUploads('clerk')"
          >
            Yes, send request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Approve dialog -->
    <v-dialog
      v-model="isApproveDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Approve request
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isApproveDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-1"
          ></v-text-field>
          This action will update receipt with the request changes, ensure that all fields are filled correctly as this record will not be editable after approval.
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="approveRequest()"
          >
            Save receipt
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Reject dialog -->
    <v-dialog
      v-model="isRejectDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Reject request
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isRejectDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-1"
          ></v-text-field>
          This update the request & change status to Rejected, comment on the reason for rejecting the requested change.
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="rejectRequest()"
          >
            Reject request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiClose,
  mdiPaperclip,
  mdiOpenInNew,
  mdiCalendar,
} from '@mdi/js'
import {
  inject,
  onUnmounted,
  onMounted,
  computed,
  ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { Letterhead } from '@/components/apps/previews'
import RequestHint from '@/components/apps/request/RequestHint'
import RequestComments from '@/components/apps/request/RequestComments'
import Alert from '@/components/Alert'
import receiptStoreModule from './receiptStoreModule'
import invoiceStoreModule from '../invoice/invoiceStoreModule'

export default {
  components: {
    Letterhead,
    Alert,
    RequestHint,
    RequestComments,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')
    const { router } = useRouter()
    const requestId = router.currentRoute.params.request_id
    const RECEIPT_APP_STORE_MODULE_NAME = 'app-receipt'
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    if (!store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) {
      store.registerModule(RECEIPT_APP_STORE_MODULE_NAME, receiptStoreModule)
    }

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const receipt = ref({
      created_at: null,
      branch_id: null,
      customer_name: null,
      customer_contact_number: null,
      customer_type: null,
      broker_id: null,
      corporate_id: null,
      niagamas_loyalty_id: null,
      customer_ic_number: null,
      roc_number: null,
      payment_method_id: null,
      trace_number: null,
      partial_card_number: null,
      insurance_category_id: null,
      insurance_product_id: null,
      insurance_policy_number: null,
      car_registration_number: null,
      insurance_id: null,
      pickup_location_id: null,
      additional_fees_info_id: null,
      require_inspection: null,
      crw_number: null,
      sum_insured: null,
      insurance_premium: null,
      after_discount: null,
      voucher_code: null,
      voucher_amount: null,
      road_tax: null,
      additional_fees: null,
      net_payment: null,
      full_spd_number: null,
      insurance_policy: null,
      payment_screenshot: null,
      require_additional_process_time: null,
      processing_date: null,
      express_charges: null,
      delivery_charges: null,
      remarks: {
        general: null,
        pickup_location: null,
        additional_fees: null,
        address: null,
      },
      issuer: null,
      invoice_id: null,
      insurance_expired_at: `${Number(yearNow) + 1}-${monthNow}-${dayNow}`,
    })
    const request = ref({})
    const comments = ref([])
    const newComment = ref(null)
    const errors = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const paymentMethodOptions = ref([])
    const insuranceCategoryOptions = ref([])
    const insuranceProductOptions = ref([])
    const insuranceOptions = ref([])
    const pickupLocationOptions = ref([])
    const additionalFeesInfoOptions = ref([])
    const customerTypeOptions = ref([])
    const brokerOptions = ref([])
    const corporateOptions = ref([])
    const loyaltyOptions = ref([])
    const motorInsuranceId = ref(null)
    const otherPickupLocationId = ref(null)
    const otherAdditionalFeesInfoId = ref(null)
    const isExpiryDateMenuOpen = ref(false)
    const isAdditionalDateMenuOpen = ref(false)
    const loading = ref(false)
    const voidLoading = ref(false)
    const auditorDisabled = ref(false)
    const voidRequestDisabled = ref(false)
    const isVoidDialogVisible = ref(false)
    const isCommentDialogVisible = ref(false)
    const isApproveDialogVisible = ref(false)
    const isRejectDialogVisible = ref(false)
    const isRequestVoid = ref(false)
    const isApprovedRequest = ref(false)
    const isRespondedRequest = ref(false)
    const netPaymentRef = ref(null)
    const voucher = ref({
      code: null,
      amount: null,
    })
    const voucherIssueAmount = ref(null)
    const voucherIssued = ref({})
    const editVoucherAmountOptions = ref([
      { id: '5.00', name: '$5' },
      { id: '10.00', name: '$10' },
      { id: '20.00', name: '$20' },
    ])
    const voucherAmountOptions = ref([
      { id: null, name: 'No voucher' },
      { id: 5, name: '$5' },
      { id: 10, name: '$10' },
      { id: 20, name: '$20' },
    ])
    const initialVoucher = ref({})
    const newInsurancePolicy = ref(null)
    const newPaymentScreenshot = ref(null)
    const uploadedPolicy = ref(null)
    const uploadedScreenshot = ref(null)
    const uploadLoading = ref(false)
    const uploadScreenshotLoading = ref(false)
    const uploadCount = ref(null)
    const policyUploadRules = [value => !value || value.size < 5000000 || 'Policy file has to be lower than 5MB']
    const paymentScreenshotRules = [value => !value || value.size < 5000000 || 'Bank payment image has to be lower than 5MB']

    // Utils
    const formatDate = date => {
      if (!date) return null
      if (date.includes('/')) return date
      const [year, month] = date.split('-')

      return `${month}/${year}`
    }
    const checkNetPayment = (netPayment, afterDiscount) => {
      if (netPayment === null || afterDiscount === null) return false

      return Number(netPayment.replace(/[^0-9.-]+/g, '')) <= Number(afterDiscount.replace(/[^0-9.-]+/g, ''))
    }
    const checkProfit = () => {
      if (receipt.value.customer_type !== 'Quoted' || receipt.value.insurance_category_id !== 1) return true
      const afterDiscount = parseFloat(receipt.value.after_discount) || 0.00
      const voucherAmount = parseFloat(voucher.value.amount) || 0.00
      const finalAfterDiscount = parseFloat(Math.max(0, afterDiscount - voucherAmount))
      const netPayment = parseFloat(receipt.value.net_payment) || 0.00
      const profit = finalAfterDiscount - netPayment

      return profit >= 9
    }
    const netPaymentRules = [
      () => checkNetPayment(receipt.value.net_payment, receipt.value.after_discount) || 'Must be lower than after discount',
      () => checkProfit() || 'Profit for Quoted customer must be more than or equal to $9. Please adjust net payment',
    ]

    // Computed
    const expiryDateFormatted = computed(() => formatDate(receipt.value.insurance_expired_at))

    // Methods
    const parseCurrency = value => (value === null ? null : value.replace(/[$,]/g, ''))
    const formatCurrency = value => (parseFloat(value)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    const fetchPaymentMethods = () => {
      store
        .dispatch('app-receipt/fetchPaymentMethods')
        .then(response => {
          const { data } = response.data
          paymentMethodOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching payment methods. Please refresh!')
        })
    }
    const fetchInsuranceCategories = () => {
      store
        .dispatch('app-receipt/fetchInsuranceCategories')
        .then(response => {
          const { data } = response.data
          insuranceCategoryOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance categories. Please refresh!')
        })
    }
    const fetchInsuranceProducts = () => {
      store
        .dispatch('app-receipt/fetchInsuranceProducts')
        .then(response => {
          const { data } = response.data
          insuranceProductOptions.value = data
          motorInsuranceId.value = insuranceProductOptions.value.filter(product => product.name === 'Motor insurance')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance products. Please refresh!')
        })
    }
    const fetchInsurances = () => {
      store
        .dispatch('app-receipt/fetchInsurances')
        .then(response => {
          const { data } = response.data
          insuranceOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurances. Please refresh!')
        })
    }
    const fetchPickupLocations = () => {
      store
        .dispatch('app-receipt/fetchPickupLocations')
        .then(response => {
          const { data } = response.data
          pickupLocationOptions.value = data
          otherPickupLocationId.value = pickupLocationOptions.value.filter(pickup => pickup.name === 'Others')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching pickup location. Please refresh!')
        })
    }
    const fetchAdditionalFeesInfos = () => {
      store
        .dispatch('app-receipt/fetchAdditionalFeesInfos')
        .then(response => {
          const { data } = response.data
          additionalFeesInfoOptions.value = data
          otherAdditionalFeesInfoId.value = additionalFeesInfoOptions.value.filter(info => info.name === 'Others')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching additional fees info. Please refresh!')
        })
    }
    const fetchCustomerTypes = () => {
      store
        .dispatch('app-receipt/fetchCustomerTypes')
        .then(response => {
          const { data } = response.data
          customerTypeOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching customer types. Please refresh!')
        })
    }
    const fetchBrokers = () => {
      store
        .dispatch('app-receipt/fetchBrokers')
        .then(response => {
          const { data } = response.data
          brokerOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching brokers. Please refresh!')
        })
    }
    const fetchCorporates = () => {
      store
        .dispatch('app-receipt/fetchCorporates')
        .then(response => {
          const { data } = response.data
          corporateOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching corporates. Please refresh!')
        })
    }
    const fetchLoyalties = () => {
      store
        .dispatch('app-receipt/fetchLoyalties')
        .then(response => {
          const { data } = response.data
          loyaltyOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching niagamas loyalties. Please refresh!')
        })
    }
    const fetchVoucher = code => {
      store
        .dispatch('voucherStore/fetchVoucher', { code })
        .then(response => {
          const { data } = response.data
          voucher.value = data
          receipt.value.voucher_code = data.code
          receipt.value.voucher_amount = data.amount
          initialVoucher.value = {
            id: data.id,
            code: data.code,
            amount: data.amount,
            used_at: data.used_at,
            used_by: data.used_by,
            receipt_url: data.receipt_url,
          }
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching voucher. Please refresh!')
        })
    }
    const fetchReceipt = () => {
      store
        .dispatch('app-receipt/fetchReceipt', { id: router.currentRoute.params.id })
        .then(response => {
          const existingReceipt = response.data.data

          receipt.value.id = existingReceipt.id
          receipt.value.created_at = existingReceipt.created_at
          receipt.value.issued_at = existingReceipt.issued_at
          receipt.value.branch_id = existingReceipt.branch.id
          receipt.value.broker_id = existingReceipt.broker ? existingReceipt.broker.id : null
          receipt.value.corporate_id = existingReceipt.corporate ? existingReceipt.corporate.id : null
          receipt.value.niagamas_loyalty_id = existingReceipt.niagamas_loyalty ? existingReceipt.niagamas_loyalty.id : null
          receipt.value.customer_name = existingReceipt.customer_name
          receipt.value.customer_contact_number = existingReceipt.customer_contact_number
          receipt.value.customer_type = existingReceipt.customer_type
          receipt.value.customer_ic_number = existingReceipt.customer_ic_number
          receipt.value.roc_number = existingReceipt.roc_number
          receipt.value.payment_method_id = existingReceipt.payment_method.id
          receipt.value.trace_number = existingReceipt.trace_number
          receipt.value.partial_card_number = existingReceipt.partial_card_number
          receipt.value.insurance_category_id = existingReceipt.insurance_category.id
          receipt.value.insurance_product_id = existingReceipt.insurance_product.id
          receipt.value.car_registration_number = existingReceipt.car_registration_number
          receipt.value.insurance_policy_number = existingReceipt.insurance_policy_number
          receipt.value.insurance_id = existingReceipt.insurance.id
          receipt.value.bluecard_finance_id = existingReceipt.bluecard_finance ? existingReceipt.bluecard_finance.id : null
          receipt.value.pickup_location_id = existingReceipt.pickup_location ? existingReceipt.pickup_location.id : null
          receipt.value.additional_fees_info_id = existingReceipt.additional_fees_info ? existingReceipt.additional_fees_info.id : null
          receipt.value.require_inspection = Boolean(existingReceipt.require_inspection)
          receipt.value.crw_number = existingReceipt.crw_number
          receipt.value.require_additional_process_time = Boolean(existingReceipt.require_additional_process_time)
          if (existingReceipt.processing_date) {
            receipt.value.processing_date = new Date(existingReceipt.processing_date.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')).toISOString().substring(0, 10)
          }
          receipt.value.sum_insured = parseCurrency(existingReceipt.sum_insured)
          receipt.value.insurance_premium = parseCurrency(existingReceipt.insurance_premium)
          receipt.value.after_discount = parseCurrency(existingReceipt.after_discount)
          receipt.value.road_tax = parseCurrency(existingReceipt.road_tax)
          receipt.value.express_charges = parseCurrency(existingReceipt.express_charges)
          receipt.value.delivery_charges = parseCurrency(existingReceipt.delivery_charges)
          receipt.value.additional_fees = parseCurrency(existingReceipt.additional_fees)
          receipt.value.net_payment = parseCurrency(existingReceipt.net_payment)
          receipt.value.full_spd_number = existingReceipt.full_spd_number
          receipt.value.remarks.general = existingReceipt.general_remarks
          receipt.value.remarks.bluecard_finance = existingReceipt.bluecard_finance_remarks
          receipt.value.remarks.pickup_location = existingReceipt.pickup_location_remarks
          receipt.value.remarks.additional_fees = existingReceipt.additional_fees_remarks
          receipt.value.remarks.address = existingReceipt.address_remarks
          receipt.value.issuer = existingReceipt.issuer.nickname
          receipt.value.invoice_id = existingReceipt.invoice_id
          receipt.value.voucher_code = existingReceipt.voucher_code
          receipt.value.voucher_amount = existingReceipt.voucher_amount
          receipt.value.insurance_policy = existingReceipt.insurance_policy
          receipt.value.insurance_policy_url = existingReceipt.insurance_policy_url
          receipt.value.insurance_policy_name = existingReceipt.insurance_policy_name
          receipt.value.payment_screenshot = existingReceipt.payment_screenshot
          receipt.value.payment_screenshot_url = existingReceipt.payment_screenshot_url
          receipt.value.payment_screenshot_name = existingReceipt.payment_screenshot_name
          if (existingReceipt.insurance_expired_at) {
            const [day, month, year] = existingReceipt.insurance_expired_at.split('/')
            receipt.value.insurance_expired_at = `${year}-${month}-${day}`
          }
          voucherIssued.value = existingReceipt.voucher_issued
          voucherIssueAmount.value = existingReceipt.voucher_issued.amount
          if (existingReceipt.voucher_code) fetchVoucher(existingReceipt.voucher_code)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching receipt. Please refresh!')
        })
    }
    const updateReceiptVoucher = receiptId => {
      const receiptParam = request.value.created_at ? request.value : receipt.value
      receiptParam.voucher_code = initialVoucher.value.code ? initialVoucher.value.code : null
      receiptParam.voucher_amount = initialVoucher.value.amount ? initialVoucher.value.amount : null
      store
        .dispatch('app-receipt/updateReceipt', { id: receiptId, receipt: receiptParam })
        .then(response => {
          const { data } = response.data
          router.push(`/apps/receipts/preview/${data.id}`)
        })
        .catch(() => {
          router.push(`/apps/receipts/preview/${receiptId}`)
          snackbarService.error('Something went wrong while updating voucher & receipt. Please contact admin!')
        })
    }
    const updatePreviousVoucher = receiptId => {
      if (requestId) {
        initialVoucher.value.code = receipt.value.voucher_code
        initialVoucher.value.amount = receipt.value.voucher_amount
      }
      initialVoucher.value.used_at = null
      initialVoucher.value.used_by = null
      initialVoucher.value.receipt_url = null
      store
        .dispatch('voucherStore/updateVoucher', initialVoucher.value)
        .then(() => {
          router.push(`/apps/receipts/preview/${receiptId}`)
        })
        .catch(() => {
          router.push(`/apps/receipts/preview/${receiptId}`)
          snackbarService.error('Something went wrong while updating previous voucher. Please contact admin!')
        })
    }
    const updateVoucher = receiptId => {
      if (requestId) {
        voucher.value.code = request.value.voucher_code
        voucher.value.amount = request.value.voucher_amount
      }
      voucher.value.used_at = requestId ? request.value.requested_at : new Date()
      voucher.value.used_by = requestId ? request.value.requested_by.nickname : userData.nickname
      voucher.value.receipt_url = `${window.location.origin}/apps/receipts/preview/${receiptId}`
      store
        .dispatch('voucherStore/updateVoucher', voucher.value)
        .then(() => {
          if ((initialVoucher.value.id && (initialVoucher.value.id !== voucher.value.id))
            || (requestId && (receipt.value.voucher_code !== null) && (receipt.value.voucher_code !== request.value.voucher_code))) {
            updatePreviousVoucher(receiptId)
          } else {
            router.push(`/apps/receipts/preview/${receiptId}`)
          }
        })
        .catch(() => {
          updateReceiptVoucher(receiptId)
        })
    }
    const removeVoucher = receiptId => {
      if (requestId) {
        initialVoucher.value.code = receipt.value.voucher_code
        initialVoucher.value.amount = receipt.value.voucher_amount
      }
      initialVoucher.value.used_at = null
      initialVoucher.value.used_by = null
      initialVoucher.value.receipt_url = null
      store
        .dispatch('voucherStore/updateVoucher', initialVoucher.value)
        .then(() => {
          router.push(`/apps/receipts/preview/${receiptId}`)
        })
        .catch(() => {
          updateReceiptVoucher(receiptId)
        })
    }
    const updateReceipt = (policy = null, screenshot = null) => {
      delete receipt.value.insurance_policy_url
      delete receipt.value.insurance_policy_name
      if (policy) {
        receipt.value.insurance_policy = policy.signed_id
      }
      delete receipt.value.payment_screenshot_url
      delete receipt.value.payment_screenshot_name
      if (screenshot) {
        receipt.value.payment_screenshot = screenshot.signed_id
      }
      if (receipt.value.insurance_expired_at) {
        receipt.value.insurance_expired_at = `${receipt.value.insurance_expired_at}-01`
      }
      const updateParam = {
        id: router.currentRoute.params.id,
        receipt: receipt.value,
        voucher_issued_amount: voucherIssueAmount.value, // Ignore on null
      }

      // Edit voucher issued
      if (voucherIssued.value) {
        updateParam.voucher_issued_code = voucherIssued.value.code

        // Skip updating voucher if amount not changed
        if (voucherIssueAmount.value === voucherIssued.value.amount) {
          updateParam.voucher_issued_amount = null
          updateParam.voucher_issued_code = null
        }
      }
      store
        .dispatch('app-receipt/updateReceipt', updateParam)
        .then(response => {
          const { data } = response.data
          if (voucher.value.id) {
            updateVoucher(data.id)
          } else if (initialVoucher.value.id) {
            removeVoucher(data.id)
          } else {
            router.push(`/apps/receipts/preview/${data.id}`)
          }
          loading.value = false
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while updating receipt. Please refresh!')
        })
    }
    const isCurrentVoucher = () => {
      if (initialVoucher.value.code === voucher.value.code) return true

      return false
    }
    const resetVoucher = () => {
      voucher.value = {
        id: initialVoucher.value.id,
        code: initialVoucher.value.code,
        amount: initialVoucher.value.amount,
        used_at: initialVoucher.value.used_at,
        used_by: initialVoucher.value.used_by,
        receipt_url: initialVoucher.value.receipt_url,
      }
      receipt.value.voucher_code = initialVoucher.value.code
      receipt.value.voucher_amount = initialVoucher.value.amount
    }
    const checkVoucher = () => {
      if (isCurrentVoucher()) {
        resetVoucher()

        return
      }
      store
        .dispatch('voucherStore/checkVoucher', { code: voucher.value.code })
        .then(response => {
          voucher.value = response.data.data
          receipt.value.voucher_code = voucher.value.code
          receipt.value.voucher_amount = voucher.value.amount
          snackbarService.success(response.data.message)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching voucher. Please refresh!')
        })
    }
    const clearVoucherUse = () => {
      if (voucher.value.id === null) return
      voucher.value.id = null
      voucher.value.amount = ''
      receipt.value.voucher_code = null
      receipt.value.voucher_amount = null
    }
    const clearPaymentDetailNumbers = () => {
      receipt.value.trace_number = null
      receipt.value.partial_card_number = null
    }
    const clearRoadTaxInfo = () => {
      receipt.value.car_registration_number = null
      receipt.value.road_tax = null
      receipt.value.require_inspection = null
      receipt.value.crw_number = null
      receipt.value.require_additional_process_time = null
      receipt.value.processing_date = null
      receipt.value.express_charges = null
      receipt.value.delivery_charges = null
      receipt.value.remarks.address = null
      receipt.value.pickup_location_id = null
      receipt.value.remarks.pickup_location = null
    }
    const clearDeliveryAddress = () => {
      receipt.value.remarks.address = null
    }
    const isNumber = event => {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = event.key
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault()
      }
    }
    const createComment = id => {
      store
        .dispatch('requestStore/createComment', {
          user_id: userData.id,
          approval_request_id: id || requestId,
          comment: newComment.value,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving comment. Please refresh!')
        })
    }
    const reassignEmptyString = () => {
      const attributes = ['additional_fees', 'net_payment', 'road_tax']
      attributes.forEach(attr => {
        if (receipt.value[attr] === null) receipt.value[attr] = ''
      })
    }
    const saveRequest = (policy = null, screenshot = null) => {
      loading.value = true
      delete receipt.value.issuer
      if (!receipt.value.require_inspection) {
        delete receipt.value.crw_number
      }
      delete receipt.value.insurance_policy
      delete receipt.value.insurance_policy_url
      delete receipt.value.insurance_policy_name
      if (policy) {
        receipt.value.insurance_policy = policy.signed_id
      }
      delete receipt.value.payment_screenshot
      delete receipt.value.payment_screenshot_url
      delete receipt.value.payment_screenshot_name
      if (screenshot) {
        receipt.value.payment_screenshot = screenshot.signed_id
      }
      if (receipt.value.insurance_expired_at) {
        receipt.value.insurance_expired_at = `${receipt.value.insurance_expired_at}-01`
      }
      reassignEmptyString()
      const requestParam = {
        record_id: receipt.value.id,
        record_type: 'Receipt',
        requested_by_id: userData.id,
        action: 'Edit',
        params: receipt.value,
        rejected_at: null,
        responded_by: null,
        comment: newComment.value,
      }
      if (policy) {
        requestParam.insurance_policy = policy.signed_id
      }
      if (screenshot) {
        requestParam.payment_screenshot = screenshot.signed_id
      }
      if (requestId) requestParam.id = requestId

      requestParam.params.voucher_issued_amount = voucherIssueAmount.value

      // Request edit voucher issued
      if (voucherIssued.value) {
        requestParam.params.voucher_issued_code = voucherIssued.value.code

        // Skip updating voucher if amount not changed
        if (voucherIssueAmount.value === voucherIssued.value.amount) {
          requestParam.params.voucher_issued_amount = null
          requestParam.params.voucher_issued_code = null
        }
      }
      store
        .dispatch(`requestStore/${requestId ? 'updateRequest' : 'createRequest'}`, requestParam)
        .then(response => {
          if (newComment.value) {
            createComment(response.data.data.id)
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          loading.value = false
          isCommentDialogVisible.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving request. Please refresh!')
        })
    }
    const fetchRequest = () => {
      store
        .dispatch('requestStore/fetchRequest', { id: requestId })
        .then(response => {
          let receiptAttrsInField = response.data.data.record
          if (response.data.data.approved_at) {
            isApprovedRequest.value = true
            receiptAttrsInField = response.data.data.result
          }
          const existingReceipt = receiptAttrsInField
          receipt.value.id = response.data.data.record.id
          receipt.value.created_at = existingReceipt.created_at
          receipt.value.issued_at = existingReceipt.issued_at
          receipt.value.branch_id = existingReceipt.branch.id
          receipt.value.broker_id = existingReceipt.broker ? existingReceipt.broker.id : null
          receipt.value.corporate_id = existingReceipt.corporate ? existingReceipt.corporate.id : null
          receipt.value.niagamas_loyalty_id = existingReceipt.niagamas_loyalty ? existingReceipt.niagamas_loyalty.id : null
          receipt.value.customer_name = existingReceipt.customer_name
          receipt.value.customer_contact_number = existingReceipt.customer_contact_number
          receipt.value.customer_type = existingReceipt.customer_type
          receipt.value.customer_ic_number = existingReceipt.customer_ic_number
          receipt.value.roc_number = existingReceipt.roc_number
          receipt.value.payment_method_id = existingReceipt.payment_method.id
          receipt.value.trace_number = existingReceipt.trace_number
          receipt.value.partial_card_number = existingReceipt.partial_card_number
          receipt.value.insurance_category_id = existingReceipt.insurance_category.id
          receipt.value.insurance_product_id = existingReceipt.insurance_product.id
          receipt.value.car_registration_number = existingReceipt.car_registration_number
          receipt.value.insurance_policy_number = existingReceipt.insurance_policy_number
          receipt.value.insurance_id = existingReceipt.insurance.id
          receipt.value.bluecard_finance_id = existingReceipt.bluecard_finance ? existingReceipt.bluecard_finance.id : null
          receipt.value.pickup_location_id = existingReceipt.pickup_location ? existingReceipt.pickup_location.id : null
          receipt.value.additional_fees_info_id = existingReceipt.additional_fees_info ? existingReceipt.additional_fees_info.id : null
          receipt.value.require_inspection = Boolean(existingReceipt.require_inspection)
          receipt.value.crw_number = existingReceipt.crw_number
          receipt.value.require_additional_process_time = Boolean(existingReceipt.require_additional_process_time)
          if (existingReceipt.processing_date) {
            receipt.value.processing_date = new Date(existingReceipt.processing_date.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')).toISOString().substring(0, 10)
          }
          receipt.value.sum_insured = parseCurrency(existingReceipt.sum_insured)
          receipt.value.insurance_premium = parseCurrency(existingReceipt.insurance_premium)
          receipt.value.after_discount = parseCurrency(existingReceipt.after_discount)
          receipt.value.road_tax = parseCurrency(existingReceipt.road_tax)
          receipt.value.express_charges = parseCurrency(existingReceipt.express_charges)
          receipt.value.delivery_charges = parseCurrency(existingReceipt.delivery_charges)
          receipt.value.additional_fees = parseCurrency(existingReceipt.additional_fees)
          receipt.value.net_payment = parseCurrency(existingReceipt.net_payment)
          receipt.value.full_spd_number = existingReceipt.full_spd_number
          receipt.value.remarks.general = existingReceipt.general_remarks
          receipt.value.remarks.bluecard_finance = existingReceipt.bluecard_finance_remarks
          receipt.value.remarks.pickup_location = existingReceipt.pickup_location_remarks
          receipt.value.remarks.additional_fees = existingReceipt.additional_fees_remarks
          receipt.value.remarks.address = existingReceipt.address_remarks
          receipt.value.issuer = existingReceipt.issuer.nickname
          receipt.value.invoice_id = existingReceipt.invoice_id
          receipt.value.voucher_code = existingReceipt.voucher_code
          receipt.value.voucher_amount = existingReceipt.voucher_amount
          receipt.value.insurance_expired_at = existingReceipt.insurance_expired_at
          voucherIssued.value = existingReceipt.voucher_issued
          voucherIssueAmount.value = existingReceipt.voucher_issued ? existingReceipt.voucher_issued.amount : null
          if (existingReceipt.insurance_policy_url) {
            receipt.value.insurance_policy_url = existingReceipt.insurance_policy_url
            receipt.value.insurance_policy_name = existingReceipt.insurance_policy_name
          }
          if (existingReceipt.payment_screenshot_url) {
            receipt.value.payment_screenshot_url = existingReceipt.payment_screenshot_url
            receipt.value.payment_screenshot_name = existingReceipt.payment_screenshot_name
          }
          if (existingReceipt.insurance_expired_at) {
            const [day, month, year] = existingReceipt.insurance_expired_at.split('/')
            receipt.value.insurance_expired_at = `${year}-${month}-${day}`
          }
          if (existingReceipt.voucher_code) fetchVoucher(existingReceipt.voucher_code)
          receipt.value.deleted_at = existingReceipt.deleted_at

          if (response.data.data.result) {
            request.value = response.data.data.result
            request.value.sum_insured = parseCurrency(request.value.sum_insured)
            request.value.insurance_premium = parseCurrency(request.value.insurance_premium)
            request.value.after_discount = parseCurrency(request.value.after_discount)
            request.value.road_tax = parseCurrency(request.value.road_tax)
            request.value.express_charges = parseCurrency(request.value.express_charges)
            request.value.delivery_charges = parseCurrency(request.value.delivery_charges)
            request.value.additional_fees = parseCurrency(request.value.additional_fees)
            request.value.net_payment = parseCurrency(request.value.net_payment)
            if (request.value.request_voucher_issued_amount) {
              request.value.voucher_issued_amount = parseCurrency(parseFloat(request.value.request_voucher_issued_amount).toFixed(2))
            }
            if (request.value.insurance_expired_at) {
              const requestDate = request.value.insurance_expired_at.split('/')
              request.value.insurance_expired_at = `${requestDate[1]}/${requestDate[2]}`
            }
          }
          request.value.requested_by = response.data.data.requested_by
          request.value.responded_by = response.data.data.responded_by
          request.value.record_number = response.data.data.record.receipt_number
          request.value.requested_at = response.data.data.requested_at
          isRequestVoid.value = response.data.data.action === 'Void'
          if (response.data.data.responded_by) isRespondedRequest.value = true
          if (response.data.data.approved_at) isApprovedRequest.value = true
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching request. Please refresh!')
        })
    }
    const fetchRequestedReceipt = () => {
      store
        .dispatch('requestStore/fetchRequest', { id: requestId })
        .then(response => {
          const returnedReceipt = response.data.data.result

          receipt.value.id = response.data.data.record.id
          receipt.value.created_at = returnedReceipt.created_at
          receipt.value.receipt_number = returnedReceipt.receipt_number
          receipt.value.issued_at = returnedReceipt.issued_at
          receipt.value.branch_id = returnedReceipt.branch.id
          receipt.value.broker_id = returnedReceipt.broker ? returnedReceipt.broker.id : null
          receipt.value.corporate_id = returnedReceipt.corporate ? returnedReceipt.corporate.id : null
          receipt.value.niagamas_loyalty_id = returnedReceipt.niagamas_loyalty ? returnedReceipt.niagamas_loyalty.id : null
          receipt.value.customer_name = returnedReceipt.customer_name
          receipt.value.customer_contact_number = returnedReceipt.customer_contact_number
          receipt.value.customer_type = returnedReceipt.customer_type
          receipt.value.customer_ic_number = returnedReceipt.customer_ic_number
          receipt.value.roc_number = returnedReceipt.roc_number
          receipt.value.payment_method_id = returnedReceipt.payment_method.id
          receipt.value.trace_number = returnedReceipt.trace_number
          receipt.value.partial_card_number = returnedReceipt.partial_card_number
          receipt.value.insurance_category_id = returnedReceipt.insurance_category.id
          receipt.value.insurance_product_id = returnedReceipt.insurance_product.id
          receipt.value.car_registration_number = returnedReceipt.car_registration_number
          receipt.value.insurance_policy_number = returnedReceipt.insurance_policy_number
          receipt.value.insurance_id = returnedReceipt.insurance.id
          receipt.value.bluecard_finance_id = returnedReceipt.bluecard_finance ? returnedReceipt.bluecard_finance.id : null
          receipt.value.pickup_location_id = returnedReceipt.pickup_location ? returnedReceipt.pickup_location.id : null
          receipt.value.additional_fees_info_id = returnedReceipt.additional_fees_info ? returnedReceipt.additional_fees_info.id : null
          receipt.value.require_inspection = Boolean(returnedReceipt.require_inspection)
          receipt.value.crw_number = returnedReceipt.crw_number
          receipt.value.require_additional_process_time = Boolean(returnedReceipt.require_additional_process_time)
          if (returnedReceipt.processing_date) {
            receipt.value.processing_date = new Date(returnedReceipt.processing_date.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')).toISOString().substring(0, 10)
          }
          receipt.value.sum_insured = parseCurrency(returnedReceipt.sum_insured)
          receipt.value.insurance_premium = parseCurrency(returnedReceipt.insurance_premium)
          receipt.value.after_discount = parseCurrency(returnedReceipt.after_discount)
          receipt.value.road_tax = parseCurrency(returnedReceipt.road_tax)
          receipt.value.express_charges = parseCurrency(returnedReceipt.express_charges)
          receipt.value.delivery_charges = parseCurrency(returnedReceipt.delivery_charges)
          receipt.value.additional_fees = parseCurrency(returnedReceipt.additional_fees)
          receipt.value.net_payment = parseCurrency(returnedReceipt.net_payment)
          receipt.value.full_spd_number = returnedReceipt.full_spd_number
          receipt.value.remarks.general = returnedReceipt.general_remarks
          receipt.value.remarks.bluecard_finance = returnedReceipt.bluecard_finance_remarks
          receipt.value.remarks.pickup_location = returnedReceipt.pickup_location_remarks
          receipt.value.remarks.additional_fees = returnedReceipt.additional_fees_remarks
          receipt.value.remarks.address = returnedReceipt.address_remarks
          receipt.value.issuer = returnedReceipt.issuer.nickname
          receipt.value.invoice_id = returnedReceipt.invoice_id
          receipt.value.voucher_code = returnedReceipt.voucher_code
          receipt.value.voucher_amount = returnedReceipt.voucher_amount
          receipt.value.insurance_expired_at = returnedReceipt.insurance_expired_at
          voucherIssued.value = returnedReceipt.voucher_issued
          voucherIssueAmount.value = returnedReceipt.request_voucher_issued_amount
          if (returnedReceipt.insurance_policy_url) {
            receipt.value.insurance_policy_url = returnedReceipt.insurance_policy_url
            receipt.value.insurance_policy_name = returnedReceipt.insurance_policy_name
          }
          if (returnedReceipt.payment_screenshot_url) {
            receipt.value.payment_screenshot_url = returnedReceipt.payment_screenshot_url
            receipt.value.payment_screenshot_name = returnedReceipt.payment_screenshot_name
          }
          if (returnedReceipt.insurance_expired_at) {
            const [day, month, year] = returnedReceipt.insurance_expired_at.split('/')
            receipt.value.insurance_expired_at = `${year}-${month}-${day}`
          }
          if (returnedReceipt.voucher_code) fetchVoucher(returnedReceipt.voucher_code)
          if (response.data.data.approved_at) isApprovedRequest.value = true
          receipt.value.deleted_at = returnedReceipt.deleted_at
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching request. Please refresh!')
        })
    }
    const approveRequest = () => {
      loading.value = true
      store
        .dispatch('requestStore/approveRequest', { id: requestId, comment: newComment.value })
        .then(response => {
          if (newComment.value) createComment()
          if (receipt.value.voucher_code !== null && receipt.value.voucher_code && !request.value.voucher_code) {
            removeVoucher(receipt.value.id)
          } else if (request.value.voucher_code !== null && receipt.value.voucher_code !== request.value.voucher_code) {
            updateVoucher(receipt.value.id)
          } else {
            snackbarService.success(response.data.message)
            router.push(`/apps/receipts/preview/${receipt.value.id}`)
          }
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while approving request. Please refresh!')
        })
    }
    const rejectRequest = () => {
      loading.value = true
      store
        .dispatch('requestStore/rejectRequest', { id: requestId, comment: newComment.value })
        .then(response => {
          if (newComment.value) {
            createComment()
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while rejecting request. Please refresh!')
        })
    }
    const voidRequest = () => {
      voidLoading.value = true
      store
        .dispatch('requestStore/voidRequest', { id: requestId })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding request. Please refresh!')
        })
    }
    const fetchComments = () => {
      store
        .dispatch('requestStore/fetchComments', { request_id: requestId })
        .then(response => {
          const { data } = response.data
          comments.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching comments. Please refresh!')
        })
    }
    const isReceiptEdit = () => {
      if ((requestId && can('write', 'ReportActions'))) {
        // Auditor view request
        auditorDisabled.value = true
        fetchRequest()
      } else if (router.currentRoute.params.action === 'Void') {
        voidRequestDisabled.value = true
        fetchRequest()
      } else if (requestId) {
        // Clerk edit request
        fetchRequestedReceipt()
      } else {
        // Edit receipt / create request
        fetchReceipt()
      }
    }
    const roleUpdate = role => {
      if (role === 'auditor') {
        updateReceipt(uploadedPolicy.value, uploadedScreenshot.value)
      } else if (role === 'clerk') {
        saveRequest(uploadedPolicy.value, uploadedScreenshot.value)
      }
    }
    const directUpload = (file, isLoading, output, role) => {
      isLoading = true
      store
        .dispatch(`app-receipt/${output === 'insurance_policy' ? 'directUploadPolicy' : 'directUploadScreenshot'}`, file)
        .then(response => {
          isLoading = false
          if (output === 'insurance_policy') {
            uploadedPolicy.value = response
          } else if (output === 'payment_screenshot') {
            uploadedScreenshot.value = response
          }
          uploadCount.value -= 1
          if (uploadCount.value === 0) roleUpdate(role)
        })
        .catch(error => {
          isLoading = false
          snackbarService.error(error.response.data.message || 'Something went wrong while uploading file. Please refresh!')
        })
    }
    const handleUploads = role => {
      const uploads = [
        { file: newInsurancePolicy.value, isLoading: uploadLoading.value, output: 'insurance_policy' },
        { file: newPaymentScreenshot.value, isLoading: uploadScreenshotLoading.value, output: 'payment_screenshot' },
      ]
      uploads.forEach(upload => {
        if (upload.file) directUpload(upload.file, upload.isLoading, upload.output, role)
      })
      if (!newInsurancePolicy.value && !newPaymentScreenshot.value) roleUpdate(role)
    }
    const uploadCountChange = () => {
      uploadCount.value = 0
      if (newInsurancePolicy.value) uploadCount.value += 1
      if (newPaymentScreenshot.value) uploadCount.value += 1
    }
    const updateProcessingDate = event => {
      receipt.value.processing_date = !event ? null : new Date().toISOString().substring(0, 10)
    }
    const customerTypeChange = () => {
      receipt.value.broker_id = null
      receipt.value.corporate_id = null
      receipt.value.niagamas_loyalty_id = null
      if (receipt.value.customer_type === 'Non quoted/Broker' || receipt.value.customer_type === 'Corporate' || receipt.value.customer_type === 'Niagamas loyalty') {
        receipt.value.customer_name = null
        receipt.value.customer_contact_number = null
      }
      receipt.value.customer_ic_number = null
      receipt.value.roc_number = null
    }
    const brokerChange = () => {
      const broker = brokerOptions.value.find(b => b.id === receipt.value.broker_id)
      receipt.value.customer_contact_number = broker.contact_number
      receipt.value.customer_ic_number = broker.ic_number
    }
    const corporateChange = () => {
      const corporate = corporateOptions.value.find(c => c.id === receipt.value.corporate_id)
      receipt.value.customer_contact_number = corporate.contact_person_number
      receipt.value.roc_number = corporate.roc_number
    }
    const loyaltyChange = () => {
      const loyalty = loyaltyOptions.value.find(l => l.id === receipt.value.niagamas_loyalty_id)
      receipt.value.customer_contact_number = loyalty.contact_number
      receipt.value.customer_ic_number = loyalty.ic_number
    }

    // Computed
    const branchOptions = computed(() => store.state.branches.filter(b => b.active))
    const isMotorInsurance = computed(() => (motorInsuranceId.value === receipt.value.insurance_product_id))
    const isOtherPickupLocation = computed(() => {
      if (otherPickupLocationId.value !== receipt.value.pickup_location_id) receipt.value.remarks.pickup_location = null

      return otherPickupLocationId.value === receipt.value.pickup_location_id
    })
    const isOtherAdditionalFeesInfo = computed(() => {
      if (otherAdditionalFeesInfoId.value !== receipt.value.additional_fees_info_id) receipt.value.remarks.additional_fees = null

      return otherAdditionalFeesInfoId.value === receipt.value.additional_fees_info_id
    })
    const calculatedTotal = computed(() => {
      const afterDiscount = parseFloat(receipt.value.after_discount) || 0.00
      const roadTax = parseFloat(receipt.value.road_tax) || 0.00
      const additionalFees = parseFloat(receipt.value.additional_fees) || 0.00
      const expressCharges = parseFloat(receipt.value.express_charges) || 0.00
      const deliveryCharges = parseFloat(receipt.value.delivery_charges) || 0.00
      const voucherAmount = parseFloat(voucher.value.amount) || 0.00
      const finalAfterDiscount = parseFloat(Math.max(0, afterDiscount - voucherAmount))
      const sumTotal = finalAfterDiscount + roadTax + additionalFees + expressCharges + deliveryCharges

      return parseFloat(sumTotal).toFixed(2)
    })

    // Mounted
    onMounted(() => {
      if (requestId) fetchComments()
      isReceiptEdit()
      fetchPaymentMethods()
      fetchInsuranceCategories()
      fetchInsuranceProducts()
      fetchInsurances()
      fetchPickupLocations()
      fetchAdditionalFeesInfos()
      fetchCustomerTypes()
      fetchBrokers()
      fetchCorporates()
      fetchLoyalties()
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_APP_STORE_MODULE_NAME)
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      can,
      router,
      receipt,
      errors,
      branchOptions,
      paymentMethodOptions,
      insuranceCategoryOptions,
      insuranceProductOptions,
      insuranceOptions,
      pickupLocationOptions,
      additionalFeesInfoOptions,
      customerTypeOptions,
      brokerOptions,
      corporateOptions,
      loyaltyOptions,
      isMotorInsurance,
      isOtherPickupLocation,
      isOtherAdditionalFeesInfo,
      calculatedTotal,
      updateReceipt,
      clearPaymentDetailNumbers,
      clearRoadTaxInfo,
      clearDeliveryAddress,
      loading,
      voidLoading,
      auditorDisabled,
      voidRequestDisabled,
      isRequestVoid,
      isApprovedRequest,
      isRespondedRequest,
      netPaymentRef,
      checkNetPayment,
      expiryDateFormatted,
      voucher,
      checkVoucher,
      clearVoucherUse,
      isNumber,
      isExpiryDateMenuOpen,
      isAdditionalDateMenuOpen,
      formatCurrency,
      request,
      saveRequest,
      approveRequest,
      rejectRequest,
      voidRequest,
      requestId,
      newComment,
      comments,
      fetchComments,
      isVoidDialogVisible,
      isCommentDialogVisible,
      isApproveDialogVisible,
      isRejectDialogVisible,
      policyUploadRules,
      paymentScreenshotRules,
      netPaymentRules,
      newInsurancePolicy,
      newPaymentScreenshot,
      uploadLoading,
      uploadScreenshotLoading,
      handleUploads,
      uploadCount,
      uploadCountChange,
      voucherIssued,
      voucherIssueAmount,
      voucherAmountOptions,
      editVoucherAmountOptions,
      updateProcessingDate,
      customerTypeChange,
      brokerChange,
      corporateChange,
      loyaltyChange,

      icons: {
        mdiClose,
        mdiPaperclip,
        mdiOpenInNew,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/style.scss';

.app-receipt {
  .autofill-button {
    margin-top: 40px;
  }

  .receipt-add-branch {
    width: 15rem;
  }
}

.voucher-amount-edit .v-input__control .v-input__slot {
  min-height: auto !important;
  align-items: center !important;
  height: 30px !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 !important;
}
</style>
